import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Error404.css';

const Error404 = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            } else {
                navigate('/');
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown, navigate]);

    return (
            <div className="notfound"  data-aos="zoom-in-up">
                <div className="notfound-content">
                    <h2>404 - Nie znaleziono strony</h2>
                    <p>Przykro nam, strona, której szukasz, nie istnieje.</p>
                    <p>Przekierowanie za {countdown} sekund.</p>
                    <button className="button-notfound" onClick={() => navigate('/')}>Powrót na stronę główną</button>
                </div>
            </div>
    );
}

export default Error404;