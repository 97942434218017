import React, { useState, useEffect } from 'react';
import './NaszeProjekty.css';

const NaszeProjekty = () => {
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setGallery(data.gallery))
            .catch(error => console.error('Error loading gallery images:', error));
    }, []);

    if (gallery.length === 0) {
        return <div className="loader">
            <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
            </div>
            <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
            </div>
            <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
            </div>
            <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
            </div>
        </div>;
    }

    return (
        <div className="gallery-container">
            <h1 className="gallery-title">Nasze Projekty</h1>
            <p className="gallery-description">Oto galeria naszych projektów.</p>
            <div className="gallery-cards">
                {gallery.map((image, index) => (
                    <img key={index} src={image} alt={`Projekt ${index + 1}`} className="gallery-image" />
                ))}
            </div>
        </div>
    );
}

export default NaszeProjekty;
