import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onClose, success }) => {
    const navigate = useNavigate();

    const handleCloseAndRedirect = () => {
        onClose();
        navigate('/');
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {}}
            className="confirmation-modal"
            overlayClassName="confirmation-modal-overlay"
        >
            <div className="confirmation-content">
                {success ? (
                    <>
                        <h2>Wiadomość została pomyślnie wysłana!</h2>
                        <p>Dziękujemy za kontakt. Twoje zapytanie zostało przekazane do naszego zespołu i postaramy się odpowiedzieć jak najszybciej.</p>
                    </>
                ) : (
                    <>
                        <h2>Wystąpił błąd podczas wysyłania wiadomości.</h2>
                        <p>Nie udało się wysłać Twojej wiadomości. Spróbuj ponownie później lub skontaktuj się z nami bezpośrednio.</p>
                    </>
                )}
                <button className="button-contact" onClick={handleCloseAndRedirect}>Powrót do strony głównej</button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
