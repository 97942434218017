import React from 'react'; 
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from './config';

class ReCAPTCHAV3 extends React.Component {
  async verifyCallback(recaptchaToken) {
    console.log("ReCAPTCHA token:", recaptchaToken);
  }

  render() {
    return (
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        size="invisible" 
        onChange={(recaptchaToken) => this.verifyCallback(recaptchaToken)}
      />
    );
  }
}

export default ReCAPTCHAV3;
