import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavigationBar.css';
import mebleLogo from '../assets/PXSA31j.png';

const NavigationBar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={mebleLogo} alt="Meble Logo" className="logo-image"/>
            </div>
            <div className="navbar-links">
                <NavLink exact to="/" activeClassName="active" className="nav-item">Strona Główna</NavLink>
                <NavLink to="/oferty" activeClassName="active" className="nav-item">Oferty</NavLink>
                <NavLink to="/nasze-projekty" activeClassName="active" className="nav-item">Nasze Projekty</NavLink>
                <NavLink to="/kontakt" activeClassName="active" className="nav-item special-button">Wycena
                    Projektu</NavLink>
            </div>
        </nav>
    );
}

export default NavigationBar;
