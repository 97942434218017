import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import NavigationBar from './component/NavigationBar';
import Footer from './component/Footer';
import NaszeProjekty from './component/NaszeProjekty'
import Home from './component/Home';
import Uslugi from './component/Uslugi';
import './App.css';
import Error404 from "./component/Error404";
import Kontakt from "./component/Kontakt";
import ReCAPTCHAV3 from "./ReCAPTCHAV3";


function App() {
    return (
         <Router>
             <ReCAPTCHAV3/>
            <NavigationBar />
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route path="*" element={<Error404 />} />
                <Route path="/oferty" element={<Uslugi/>} />
                <Route path="/nasze-projekty" element={<NaszeProjekty/>} />
                <Route path="/kontakt" element={<Kontakt/>} />
            </Routes>
             <Footer/>
          </Router>
  );
}

export default App;
