import React from 'react';
import './Home.css';

const Home = () => {
    return (
        <div className="home">
            <div className="content">
                <h1>M.N Meble</h1>
                <p>M.N Meble to firma stolarska z wieloletnim doświadczeniem w tworzeniu wyjątkowych mebli na zamówienie. Nasza pasja do drewna i rzemiosła, w połączeniu z dbałością o najmniejsze detale, sprawiają, że każde nasze dzieło to prawdziwa perełka.</p>
                <p>Dlaczego warto wybrać M.N Meble?</p>
                <p>Indywidualne podejście: Każdy projekt jest dla nas wyjątkowy. Słuchamy Twoich potrzeb i tworzymy meble, które idealnie wpisują się w Twoje wymagania i styl życia.</p>
                <p>Najwyższa jakość materiałów: Pracujemy tylko z najlepszymi gatunkami i starannie dobranymi materiałami, aby zapewnić trwałość i elegancję naszych wyrobów.</p>
                <p>Doświadczenie i profesjonalizm: Nasi stolarze to wykwalifikowani fachowcy z pasją do drewna, którzy nieustannie podnoszą swoje kwalifikacje, by dostarczać produkty na najwyższym poziomie.</p>
                <p>Kompleksowa obsługa: Od pierwszej konsultacji, przez projektowanie, aż po montaż – jesteśmy z Tobą na każdym etapie realizacji zamówienia.</p>
            </div>
        </div>
    );
}

export default Home;
