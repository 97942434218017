import React from 'react';
import Modal from 'react-modal';
import './LinkConfirmationModal.css';

const LinkConfirmationModal = ({ isOpen, onClose, url }) => {

    const handleConfirmRedirect = () => {
        window.open(url, '_blank');
        onClose();
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            className="linkconfirmation-modal"
            overlayClassName="linkconfirmation-modal-overlay"
        >
            <div className="linkconfirmation-content">
                <h2>Czy na pewno chcesz opuścić tę stronę?</h2>
                <h3>Po upuszczeniu zostaniesz przekierowany do zewnetrznego katalogu</h3>
                <div className="buttons">
                    <button className="button-not" onClick={handleCloseModal}>Nie chcę</button>
                    <button className="button-yes" onClick={handleConfirmRedirect}>Tak chcę</button>
                </div>
            </div>
        </Modal>
    );
};

export default LinkConfirmationModal;
