import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-info">
                    <h2>M.N meble</h2>
                    <p>Adres: Radomsko, Traugutta 2</p>
                    <p>Numer telefonu: (+48) 796-300-667</p>
                    <p>Email: kontakt@mojenowemeble.pl</p>
                </div>
                <nav className="footer-nav">
                    <h3>Nawigacja</h3>
                    <ul>
                        <li><NavLink exact to="/" onClick={scrollToTop}>Strona główna</NavLink></li>
                        <li><NavLink to="/oferty" onClick={scrollToTop}>Oferty</NavLink></li>
                        <li><NavLink to="/nasze-projekty" onClick={scrollToTop}>Nasze Projekty</NavLink></li>
                        <li><NavLink to="/kontakt" onClick={scrollToTop}>Wycena Projektu</NavLink></li>
                    </ul>
                </nav>
                <div className="footer-social">
                    <h3>Social Media</h3>
                    <ul>
                        <li><a href="https://www.facebook.com/profile.php?id=61561619185832" target="_blank"
                               rel="noopener noreferrer">Facebook</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© {new Date().getFullYear()} M.N Meble. Wszelkie prawa zastrzeżone.</p>
                <p><a href="https://diego.yolospeak.pl/" target="_blank" rel="noopener noreferrer"
                                      className="footer-link">Wykonane przez: Diego</a></p>
            </div>
        </footer>
    );
};

export default Footer;
