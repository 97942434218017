import React, { useState } from 'react';
import './Kontakt.css';
import ConfirmationModal from './ConfirmationModal';

const Kontakt = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        description: ''
    });
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationSuccess, setConfirmationSuccess] = useState(false);
    const [charWarning, setCharWarning] = useState('');
    const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
    const MAX_CHARS = 4000;

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newFormData = {
            ...formData,
            [name]: value
        };

        const totalCharCount = Object.values(newFormData).reduce((sum, val) => sum + val.length, 0);

        if (totalCharCount > MAX_CHARS) {
            return;
        }

        setFormData(newFormData);

        if (totalCharCount > MAX_CHARS - 200) {
            setCharWarning(`Pozostało ${MAX_CHARS - totalCharCount} znaków.`);
        } else {
            setCharWarning('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = 'https://api.mojenowemeble.pl/api/contact';
        const timeoutMs = 10000; // 10 sec

        setIsLoading(true);

        const controller = new AbortController();
        const signal = controller.signal;

        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
                controller.abort();
                reject(new Error('Request timed out'));
            }, timeoutMs);
        });

        try {
            const response = await Promise.race([
                fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData),
                    signal: signal
                }),
                timeoutPromise
            ]);

            if (response.ok) {
                setConfirmationSuccess(true);
            } else {
                setConfirmationSuccess(false);
            }
        } catch (error) {
            console.error('Error sending data to backend', error);
            setConfirmationSuccess(false);
        } finally {
            setIsLoading(false);
        }

        setFormData({
            name: '',
            email: '',
            description: ''
        });
        setConfirmationModalOpen(true);
    };


    const closeModal = () => {
        setConfirmationModalOpen(false);
    };

    return (
        <div className="contact-page">
            <h1 className="contact-title">Kontakt</h1>
            <p className="contact-description">Napisz do nas a my wycenimy twoje zamówienie.</p>

            <div className="contact-container">
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Imię</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Wpisz swoje imię"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Wpisz swój adres email"
                                required
                            />
                        </div>
                    </div>
                    <label>
                        <span className="white-text">Opis</span>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows={4}
                            placeholder="Opisz swoje zapytanie lub wiadomość"
                            required
                        />
                    </label>
                    {charWarning && <p className="char-warning">{charWarning}</p>}
                    <button className="button-contact" type="submit" disabled={isLoading}>
                        {isLoading ? 'Wysyłanie...' : 'Wyślij Zapytanie'}
                    </button>
                </form>
            </div>

            <ConfirmationModal
                isOpen={confirmationModalOpen}
                onClose={closeModal}
                success={confirmationSuccess}
            />
        </div>
    );
};

export default Kontakt;
