import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Uslugi.css';
import LinkConfirmationModal from "./LinkConfirmationModal";

const Uslugi = () => {
    const [uslugi, setUslugi] = useState([]);
    const [showExternalLinkModal, setShowExternalLinkModal] = useState(false);
    const [externalLinkUrl, setExternalLinkUrl] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setUslugi(data.uslugi))
            .catch(error => console.error('Error loading uslugi:', error));
    }, []);

    const handleUslugaClick = (url) => {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            setExternalLinkUrl(url);
            setShowExternalLinkModal(true);
        } else {
            navigate(url);
            window.scrollTo(0, 0);
        }
    };

    const handleCloseModal = () => {
        setShowExternalLinkModal(false);
    };

    if (uslugi.length === 0) {
        return (
            <div className="loader">
                <div className="circle">
                    <div className="dot"></div>
                    <div className="outline"></div>
                </div>
                <div className="circle">
                    <div className="dot"></div>
                    <div className="outline"></div>
                </div>
                <div className="circle">
                    <div className="dot"></div>
                    <div className="outline"></div>
                </div>
                <div className="circle">
                    <div className="dot"></div>
                    <div className="outline"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="uslugi-container">
            <h1 className="uslugi-title">Nasze Usługi</h1>
            <p className="uslugi-description">Oferujemy szeroką gamę usług dostosowanych do Twoich potrzeb.</p>
            <div className="cards-container">
                {uslugi.map((usluga, index) => (
                    <div className="card card-hover" key={index} onClick={() => handleUslugaClick(usluga.url)}>
                        <img src={usluga.image} alt={`${usluga.title} Logo`} className="card-image" />
                        <div className="card-content">
                            <h2 className="card-title">{usluga.title}</h2>
                            <p className="card-description">
                                {usluga.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <LinkConfirmationModal
                isOpen={showExternalLinkModal}
                onClose={handleCloseModal}
                url={externalLinkUrl}
            />
        </div>
    );
}

export default Uslugi;
